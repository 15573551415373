@import 'variables';
@import 'fonts';

html {
    position: relative;
    width: 100%;
}
html[dir='rtl'] {
    position: relative !important;
    width: 100%;
    overflow-x: hidden;
}

body {
    position: relative !important;
    font-family: var(--regular);
    font-style: normal;
    font-size: 15px;
    background-color: var(--bg);
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
body #root > div{
    overflow-x: hidden;
}
::placeholder{
    color: #a9a9a9 !important;
}
input[type='text'], input[type='number'], input[type='date']{
    color: var(--darkGrey) !important;
}
img{
    width: 100%;
}


ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

li {
    list-style: none
}

a {
    text-decoration: none;
    
}

a,
.btn {
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.btn:focus {
    text-decoration: none;
    outline: none
    
}

*::-moz-selection {
    background: var(--primary);
    color: #fff;
    text-shadow: none
}

*::-moz-selection {
    background: var(--primary) none repeat scroll 0 0;
    color: #fff;
    text-shadow: none
}

::selection {
    background: var(--primary);
    color: #fff;
    text-shadow: none
}


.textGrey{
    color: var(--textGrey);
}
.white{
    color: var(--white);
}
.darkBlue{
    color: var(--darkBlue);
}
.themeColor{
    color: var(--primary);
}
.gradientBlue{
        background: var(--gradientBlue);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    
}
.gradientGrey{
        background: var(--gradientGrey);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    
}
.gradientGrey{
    color: var(--gradientGrey);
}

.bg-textGrey{
    background: var(--textGrey);
}
.bg-white{
    background: var(--white);
}
.bg-darkBlue{
    background: var(--darkBlue);
}
.bg-themeColor{
    background: var(--primary);
}
.bg-gradientBlue{
    background: var(--gradientBlue);
}
.bg-gradientGrey{
    background: var(--gradientGrey);
}

.textarea-box {
    position: relative;
    box-shadow: 3px 3px 10px rgba(227, 227, 227, 0.8784313725);
    -webkit-box-shadow: 3px 3px 10px rgba(227, 227, 227, 0.8784313725);
    -moz-box-shadow: 3px 3px 10px rgba(227, 227, 227, 0.8784313725);
    border-radius: 20px;
}
.textarea-box > textarea {
    padding: 10px 18px 10px 18px;
    border-radius: 20px;
    background: var(--white);
    height: 105px;
    outline: none;
    border: none;
    width: 100%;
    color: var(--textGrey) !important;
}


.sticky{
    background-color: rgba(255, 255, 255, .8);
    // box-shadow: 0px 0px 3px (--themeColor);
    backdrop-filter: blur(50px);
	-webkit-backdrop-filter: blur(50px);
    -webkit-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
	-moz-box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
	box-shadow: 0px 10px 10px 0px rgba(82,80,237,0.2);
}

.common-space{
    position: relative;
    padding: 55px 0;
}


.banner-bg{
    width:100%;
    min-height: 600px;
    height:100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    padding-left: 0;
    padding-right: 0;
    .slick-slider{
        height: 100%;
        .slick-arrow{
            display: none !important;
        }
        .slick-list{
            height: 100%;
            .slick-track{
                height: 100%;
                .slick-slide{
                    & > div{
                        height: 100%;
                        .container-fluid{
                            height: 100%;
                            .container.containerMainSlider{
                                position: absolute;
                                top: 0;
                                display: flex;
                                height: 100%;
                                width: 100%;
                                align-items: center;
                                justify-content: center;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                margin: auto;
                                .sliderContentWrapper{
                                    color: var(--white);
                                    // max-width: 500px;
                                    .sliderHeading{
                                        font-size: 70px;
                                        font-family: var(--fHeading);
                                    }
                                    .sliderdetail{
                                        background-color: rgba(0, 0, 0, 0.3);
                                        padding: 10px;
                                        display: flex;
                                        align-items: center;
                                        gap: 10px;
                                        width: fit-content;
                                        position: relative;
                                        right: 0px;
                                        margin-left: auto;
                                        .sliderTxt{
                                            font-size: 22px;
                                        }
                                    }
                                    .sliderBtn{
                                        button{
                                            background: none;
                                            outline: 1px solid #fff;
                                            &:hover{
                                                outline: 1px solid var(--primary);
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                }
            }
            .mainSlide{
                background-size: cover;
                background-position: center;
                position: relative;
            }
            
        }
    }
    
}



header{
    .headerWhatsapp{
        
        button{
            height: 40px;
            background-color: var(--primary);
            &:hover{
                background-color: var(--secondary);
            }
            span{
                padding: 0 !important;
                font-family: var(--regular) !important;
            }
        }
        
    }
    .nav-bar-list {
        display: flex;
        align-items: center;
        gap: 5px;
        & > div{
            display: block;
        }
    }
    .toggleBtn {
        display: none;
    }
    .logoHeader{
        cursor: pointer;
    }
}
header.sticky{
    
    .headerWhatsapp{
        button:hover{
            border: 1px solid var(--white) !important;
        }
    }
}


.contactFluid{
    padding-top: 0px;
    padding-bottom: 0;
    // background-image: url(../images/png/map.png);
    background-repeat: no-repeat;
    background-position: top 0 left 50%;
    .contactDesc{
        text-align: center;
        margin-top: 40px;
        padding: 0 250px 0 250px;
    }
    .contactContainer{
        display: flex;
        width: 100%;
        background-color: var(--white);
        border-radius: 20px;
        margin-top: 60px;
        border: 10px solid rgba(255, 255, 255, 0.5);
        background-clip: content-box;
        position: relative;
        z-index: 5;
        .contactRow{
            padding: 0 40px 0 40px;
            .contactFormWrapper{
                button{
                    margin-right: 0;
                    margin-left: auto;
                }
            }
        }
        .contactImg{
            position: absolute;
        }
        .contactImg1{
            right: 20px;
            top: -180px;
        }
        .contactImg2{
            left: 20px;
            top: -180px;
        }

    }
}

footer{
    // background-image: url(../images/png/footerBg1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top 0 left 50%;
    
    .footerContain{
        
        padding-top: 40px;
        padding-bottom: 30px;
        .footer-list-container{
            .footer-list{
                a{
                    cursor: pointer;
                    
                }
            }
        }
    }
    .footerContentContainer{
        display: flex;
        align-items: center;
        gap: 40px;
    }
}
button span{
    padding-bottom: 3px;
}
.inputCol{
    position: relative;
}
.error-message{
    position: absolute;
    padding: 0px 0px 0 0px;
    margin: 0px 0;
    color: red !important;
    font-size: 13px !important;
    line-height: 20px;
    bottom: -19px;
}
// [dir='ltr'] .slick-slider.slick-initialized{
//     direction: ltr !important;
// }
.orderSwipe > :first-child{
    order: 2;
}
.orderSwipe > :last-child{
    order: 1;
}
.cmn-text-card{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div:first-child{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
    .cmnImg{
        max-width: 90%;
        display: block;
    }
}
.orderSwipe .cmnImg{
    float: right;
}
span.change-Btn img, span.whatsapp img{
    width: 60px;
    height: 60px;
}
// .servicesFluid{
//     padding-bottom: 50px !important;
// }
.Choose-hq{
    background-image: url(../images/png/whyBg.png);
    background-repeat: no-repeat;
    background-position: center;
}
.clientsFluid{
    background-image: url(../images/png/clientsBg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 120px;
}
.contact-heading::after{
    top: 18px !important;
}
.contactNumCol {
    gap: 10px !important;
}
.footerWrapper{
    background-image: url(../images/png/footerBg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.footerLinks .footerLinksCol a{
    cursor: pointer;
}
.imgResEn{
    display: block !important;
}
.imgResAr{
    display: none !important;
}

/***********************RTL********************/
body.rtl{
    direction: rtl;
    .banner-wapper-card{
        direction: ltr;
    }
}

// font-family: var(--arMedium);
// font-family: var(--arSemiBold);
// font-family: var(--arExtraBold);
// font-family: var(--arBlack);





body.rtl {
    
    font-family: var(--arRegular) ;
    font-size: 16px;
    p{
        font-family: var(--arRegular) !important;
        font-size: 16px !important;
    }
    button{
        span{
            font-family: var(--arRegular) !important;
            font-size: 20px !important;
            padding-top: 3px;
        }
        svg{
            -webkit-transform: scaleX(-1) !important;
            -moz-transform: scaleX(-1) !important;
            -o-transform: scaleX(-1) !important;
            transform: scaleX(-1) !important;
        }
    }
    header{
        
        .headerWhatsapp{
            button{
                
                span{
                    padding: 0 !important;
                    font-family: var(--regular) !important;
                    font-size: 15px !important;
                    direction: ltr;
                }
            }
            
        }
    }
    .banner-bg{
        .slick-slider{
            .slick-list{
                .slick-track{
                    .slick-slide{
                        & > div{
                            .container-fluid{
                                .container.containerMainSlider{
                                    // justify-content: flex-end;
                                    .sliderContentWrapper{
                                        & > *{
                                            text-align: right;
                                        }
                                        .sliderHeading{
                                            font-size: 60px;
                                            line-height: 90px;
                                            font-family: var(--arBold);
                                        }
                                        .sliderdetail{
                                            font-size: 18px;
                                            font-family: var(--arRegular);
                                            right: unset;
                                            left: 0;
                                            margin-left: 0;
                                            margin-right: auto;
                                        }
                                        .sliderBtn{
                                            justify-content: flex-end;
                                            button{
                                                direction: rtl;
                                            }
                                        }
                                    } 
                                }
                            }
                        }
                    }
                }
                
            }
        }
        
    }
    .nav-bar-list a,
    .sub-heading,
    .copyright,
    .copyright span,
    .footer-op,
    .footerAddressWr {
        font-family: var(--arRegular) !important;
    }
    .nav-bar-list a.active, .main-heading, .main-heading > *,
    .compareDataH,
    .choose-heading {
        font-family: var(--arBold) !important;
    }
    .footerLinks .footerLinksCol a,
    .footerAddress .footerAddressTitle {
        
        font-family: var(--arMedium) !important;
    }
    .servicesItemRow .servicesItemCol .servicesItemTitle {
        font-family: var(--arSemiBold) !important;
    }
    .imgResEn{
        display: none !important;
    }
    .imgResAr{
        display: block !important;
    }
    .main-heading::after{
        right: unset !important;
        left: -90px !important;
    }
    .orderSwipe .cmnImg {
        float: left !important;
    }

    .cntct-btn button {
        direction: rtl;
    }

    .contactFluid{
        
        .contactContainer{
            
            .contactRow{
                .contactFormWrapper{
                    label{
                        font-family: var(--arBold);
                    }
                    button{
                        margin-left: 0;
                        margin-right: auto;
                    }
                }
            }
            
        }
    }
    .slideTitle{
        text-align: right;
        font-family: var(--arSemiBold) !important;
    }
    p.slideText{
        text-align: right;
        font-size: 14px !important;
    }


    .slide-item .projectLocation {
        top: 50%;
        transform: translate(-40%, -50%);
        left: unset;
        font-size: 20px;
        font-family: var(--arMedium);
        letter-spacing: 0;
        rotate: 90deg;
        width: 140px;
        right: -30px;
    }
    .change-Btn {
            padding: 5px 10px 5px 7px !important;
        img{
            margin-right: 3px !important;
            margin-left: unset !important;
        }
        span{
          font-family: var(--medium) !important;
          font-size: 18px !important;
          
        }
      }

      .redefine .redefineContent {
        top: 335px !important;
        right: 640px !important;
        .main-heading-redefine{
            line-height: 75px;
        }
      }
    .project-content .compareIcon .compareCr {
        right: unset !important;
        left: -10px;
    }
    /* .Choose-hq {
        box-shadow: -10px 10px 34px rgba(0, 0, 0, 0.1);
    } */
    .servicesItemRow{
        .servicesItemCol{
            .servicesItemNumber{
                left: unset !important;
                right: 6px;
            }
            
        }
    }
    footer{
        
        .container{
            
            .footer-BG {
                right: auto;
                left: 0;
            }
            
            .footer-list-container{
                .footer-list{
                    a{
                        font-family: var(--arRegular);
                        
                    }
                }
            }
        }
        .copyRightDes{
            font-family: var(--arRegular);
            font-size: 14px;
            a{
                font-family: var(--arRegular);
            }
        }
    }
    .error-message{
        font-size: 13px !important;
    }
    .contactRowWrapper {
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
        padding-right: 0;
    }
    .cntct-btn button{
        direction: ltr;
        font-size: 15px !important;
        font-family: var(--regular) !important;
        span{
            font-size: 15px !important;
            font-family: var(--regular) !important;
        }
        svg{
            transform: scaleX(1) !important;
            -webkit-transform: scaleX(1) !important;
            -moz-transform: scaleX(1) !important;
            -ms-transform: scaleX(1) !important;
            -o-transform: scaleX(1) !important;
        }
    }
    footer .cntct-btn button {
        direction: rtl;
    }
    footer .cntct-btn button svg {
        transform: scaleX(-1) !important;
            -webkit-transform: scaleX(-1) !important;
            -moz-transform: scaleX(-1) !important;
            -ms-transform: scaleX(-1) !important;
            -o-transform: scaleX(-1) !important;
    }
    footer .cntct-btn button span {
        direction: ltr;
    }
    // .slick-slider[dir='ltr']  {
    //     float: left !important;
    //     direction: ltr !important;
    // }
    // .rtl [dir='ltr'] .slick-slide {
    //     float: left !important;
    //     direction: ltr !important;
    // }
    // /* Override RTL direction for Slick slider */
    // [dir='rtl'] .slick-slider.slick-initialized {
    //     direction: ltr !important;
    // }
    // [dir='rtl'] .slick-track{
    //     direction: ltr !important;
    // }
}




@import 'responsive';