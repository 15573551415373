@media (max-width: 1399px) {
    .redefine .redefineContent {
        top: 311px !important;
        right: 60px !important;
    }
    .footerCol1 {
        padding-right: 40px !important; 
    }
    .footerCol3 {
        padding-left: 50px !important;
      }
      .footerCol2 {
        padding-right: 10px !important;
        padding-left: 50px !important;
      }
      

}

@media (max-width: 1199px) {

    header{
        .nav-bar-list {
            gap: 1px;
            a{
                padding: 4px 4px 4px 4px !important;
            }
        }
    } 
    .headerBtns {
        gap: 1px !important;
    }
    .headerWhatsapp{
        button{
            padding: 6px 6px !important;
        }
    }
    .logoHeader svg {
        width: 180px !important;
        height: auto !important;
    }
    .contactFluid{
        .contactContainer{
            .contactImg1{
                right: -20px;
            }
            .contactImg2{
                left: 0px;
            }
        }
        .contactDesc {
            padding: 0 230px 0 230px;
        }
    }
    .servicesItemRow {
        gap: 20px;
        
    }
    .servicesItemRow{
        .servicesItemCol {
            width: calc(19% - 16px) !important;
            box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.1) !important;
            -webkit-box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.1) !important;
            -moz-box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.1) !important;
        }
    }
    .cmn-text-card .cmnImg {
        max-width: 100%;
    }
    footer{
        .footerContentContainer {
            gap: 10px;
            .footer-list{
                gap: 20px;
            }
        }
        .footer-BG{
            img{
                max-width: 410px;
            }
        }
    } 
    .redefine .redefineContent .main-heading-redefine {
        font-size: 50px !important;
    }
    .redefine .redefineContent {
        top: 257px !important;
        right: 53px !important;
    }
    .contactRow{
        flex-wrap: wrap;
        gap: 10px;
    }
    .footerCol1 {
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
      }

}


@media (max-width: 991px) {
    header{
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        .logoHeader svg {
            width: 150px !important;
            height: auto !important;
        }
        &.sticky{
            .showToggle {
                top: 66px;
            }
        }
        padding-left: 0 !important;
        padding-right: 0 !important;
        .nav-bar-list {
            display: none;
        }
        .toggleBtn {
            display: block;
            cursor: pointer;
            svg {
                color: var(--primary);
            }
        }
        .showToggle {
            position: absolute;
            top: 66px;
            left: 0;
            background: var(--white);
            color: var(--white);
            width: 100%;
            display: block;
            border-top: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
            & > div {
                border-bottom: 1px solid #d8d8d8;
                outline: none;
                width: 100%;
                a {
                    border: none !important;
                    padding: 8px 15px !important;
                    padding-bottom: 15px;
                    display: flex;
                    width: 100%;
                    color: var(--primary) !important;
                    &:hover{
                        background-color: var(--primary);
                        color: var(--white) !important;
                    }
                    &.active{
                        background-color: var(--primary);
                        color: var(--white) !important;
                    }
                }
                
            }
            
        }
        
    }
    .common-space{
        padding-top: 50px;
        padding-bottom: 0px;
    }
    .main-heading, .main-heading > *{
        font-size: 35px !important;
        line-height: 45px !important;
    }
    .main-heading{
        padding-bottom: 15px;
        &::after{
            top: unset !important;
            bottom: 0;
            right: 0 !important;
            left: 0 !important;
            margin: auto;
        }
    }
    .sub-heading{
        justify-content: center;
        text-align: center;
    }
    .cmn-text-card{
        // gap: 40px;
        & > div{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            span img{
                max-width: 400px;
            }
            .tStripe::before {
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
    .servicesItemRow{
        justify-content: center !important;
        .servicesItemCol {
            width: calc(32% - 11px) !important;
        }
    }
    .headingWrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .contactFluid{
        padding-top: 10px;
        background-position: top 30px left 50%;
        .contactContainer{
            margin-top: 20px;
            .contactImg{
                display: none;
            }
            .contactRow{
                padding-left: 10px;
                padding-right: 10px;
                iframe{
                    height: 300px;
                }
            }
            .mapWrapper{
                padding: 30px 0 20px 0 !important;
            }
            .contactFormWrapper{
                margin-top: 0;
            }
        }
        .contactDesc {
            padding: 0 0px 0 0px;
            margin-top: 30px;
        }
    }

    footer{
        .footer-BG{
            display: none;
        }
    } 
    .banner-bg {
        min-height: auto;
        max-height: auto;
        height: 400px;
        
        .slick-slider{
            .slick-arrow{
                display: none !important;
            }
            .slick-list{
                .slick-track{
                    .slick-slide{
                        & > div{
                            .container-fluid{
                                .container.containerMainSlider{
                                    
                                    .sliderContentWrapper{
                                        max-width: 100%;
                                        width: 100%;
                                        .sliderHeading{
                                            text-align: center !important;
                                            font-size: 55px;
                                        }
                                        .sliderdetail{
                                            text-align: center !important;
                                            padding: 6px 10px 6px 10px;
                                            .sliderTxt {
                                                font-size: 20px;
                                            }
                                        }
                                        .sliderBtn{
                                            justify-content: center !important;
                                            
                                        }
                                        
                                    }
                                }
                            }
                        }
                    }
                }
                .mainSlide{
                    background-size: cover;
                    background-position: center;
                    padding-top: 100px;
                    padding-bottom: 260px;
                }
                
            }
        }
        .thumbnailSlides{
            padding-left: 0;
            padding-right: 0;
            left: 0 !important;
            right: 0 !important;
            margin: auto !important;
            top: 240px !important;
            bottom: auto !important;
            
            
        }
    }
    .sliderContentWrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .sliderdetail{
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
    .orderSwipe > :first-child {
        order: 1;
        margin-bottom: 15px;
        img{
            margin-left: -5px;
            float: none;
        }
    }
    .orderSwipe > :last-child {
        order: 2;
    }
    .serviceContentContainer {
        margin-bottom: 30px !important;
    }
    .Choose-hq {
        padding: 40px 15px 0px 15px !important;
        box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.1) !important;
    }
    .compare {
        gap: 22px !important;
    }
    .project-content {
        gap: 22px !important;
    }
    .choose-heading{
        font-size: 22px !important;
    }
    .redefine .redefineContent .main-heading-redefine {
        font-size: 40px !important;
    }
    .redefine .redefineContent .main-heading-redefine {
        font-size: 35px !important;
        line-height: 60px !important;
        padding-bottom: 0 !important;
    }
    .redefine .redefineContent {
        top: 180px !important;
        right: 35px !important;
    }
    .clientHSCol{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .clientsWrapperImgs{
        grid-template-columns: repeat(5, 127px) !important;
        & > div{
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    .clientsFluid{
        padding-bottom: 35px;
    }
    .redefine {
        padding-bottom: 60px;
    }
    .contact-box {
        position: relative !important;
        width: 100%;
        margin-top: 40px !important;
        iframe{
            width: 100% !important;
            height: 400px !important;
        }
    }
    #contact {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
    #contact > div:first-child{
        order: 2;
    }
    .contact-Us {
        margin-top: 0 !important;
        box-shadow: none !important;
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
    }
    .contactRowWrapper {
        margin-top: 0px !important;
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
    }
    .contact-head{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .contactRow{
        justify-content: center !important;
    }

    .footerCol2 {
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
      }
      .footerCol2, .footerCol3 {
        margin-top: 10px;
      }

        
}

@media (max-width: 767px){
    header{
        .logoHeader{
            svg{
                width: 160px;
            }
        }
    }
    .sliderHeading{
        font-size: 45px !important;
    }
    .sliderTxt {
        font-size: 18px !important;
    }
    .banner-bg {
        height: 350px;
    }
    .servicesItemRow{
        .servicesItemCol {
            width: calc(48% - 10px) !important;
        }
    }
    .redefine .redefineContent {
        position: relative !important;
        top: unset !important;
        right: unset !important;
        left: unset !important;
    }
    .redefine .redefineContent .main-heading-redefine {
        color: var(--primary) !important;
    }
    .redefineContent{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    footer{
        .footerContentContainer {
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            .footer-list{
                gap: 15px;
            }
            .Footer-social-links{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
                .socialLinksContainer{
                    justify-content: center;
                }
                .footer-list{
                    justify-content: center;
                    flex-wrap: wrap;
                }
            }
        }
        .footer-BG{
            display: none;
        }
    } 
    .clientsWrapperImgs{
        grid-template-columns: repeat(3, 127px) !important;
    }
    .redefineContent .main-heading{
        text-align: center;
    }
    .footerCol3 {
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
      }
      .footer-row {
        // justify-content: center !important;
      }
      .footer-row .copyright{
        order: 3;
        width: 100% !important;
        margin-top: 20px;
      }
}

@media (max-width: 640px){
    

    .banner-bg {
        min-height: auto;
        max-height: auto;
        height: 300px;
        
        .slick-slider{
            .slick-arrow{
                display: none !important;
            }
            .slick-list{
                .slick-track{
                    .slick-slide{
                        & > div{
                            .container-fluid{
                                .container.containerMainSlider{
                                    height: 160px;
                                    .sliderContentWrapper{
                                        max-width: 100%;
                                        width: 100%;
                                        .sliderHeading{
                                            text-align: center !important;
                                            font-size: 35px !important;
                                            line-height: 45px;
                                        }
                                        .sliderdetail{
                                            text-align: center !important;
                                        }
                                        .sliderBtn{
                                            justify-content: center !important;
                                            
                                        }
                                        
                                    }
                                }
                            }
                        }
                    }
                }
                .mainSlide{
                    background-size: cover;
                    background-position: center;
                    padding-top: 100px;
                    padding-bottom: 165px;
                }
                
            }
        }
        .thumbnailSlides{
            top: 310px !important;
            width: 100%;
            
        }
    }

    // .aboutFluid{
    //     padding-top: 130px !important;
    // }
}


@media (max-width: 575px) {
    .main-heading, .main-heading > *{
        font-size: 30px !important;
        line-height: 40px !important;
    }
   

    footer{
        .footerContentContainer{
            .footer-list {
                gap: 10px;
            }
        } 
        .container{
            padding-bottom: 80px;
        }
        
    } 
    .footer-heading {
        font-size: 28px !important;
        margin-bottom: 0px !important;
      }
      .footerCol3 {
        margin-top: 30px !important;
      }
      footer .container {
        padding-bottom: 30px;
      }
}

@media (max-width: 520px) {
    .contactNumCol{
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px !important;
        .separator{
            display: none !important;
        }
    }
}
@media (max-width: 480px) {
    .abtUserColName{
        width: 100% !important;
    }
    .headerWhatsapp{
        display: none !important;
    }
}

@media (max-width: 430px) {
    .clientsFluid {
        padding-bottom: 55px;
    }
    .clientsWrapperImgs{
        grid-template-columns: repeat(2, 127px) !important;
        justify-content: center !important;
        gap: 15px;
        & > div{
            margin-top: 5px !important;
            margin-bottom: 5px !important;
        }
    }
    .footer-row {
        justify-content: center !important;
      }
      .footer-row .contactSocial{
        width: 100% !important;
        justify-content: center;
        margin-top: 25px;
      }
}


@media (max-width: 380px) {
    .headerWhatsapp{
        button{
            padding: 6px 10px !important;
        }
    }
    .servicesItemRow{
        .servicesItemCol {
            width: 100% !important;
        }
    }
}
@media (max-width: 350px) {
    
}


/**********************RTL*******************/

@media (max-width: 1399px){
    body.rtl {
        .redefine .redefineContent {
            top: 279px !important;
            right: 510px !important;
        }
    }
}


@media (max-width: 1199px){
    body.rtl {
        

        .banner-bg {
            
            .slick-slider{
                
                .slick-list{
                    .slick-track{
                        .slick-slide{
                            & > div{
                                .container-fluid{
                                    .container.containerMainSlider{
                                        .sliderContentWrapper{
                                            
                                            .sliderHeading{
                                                font-size: 50px;
                                                line-height: 85px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }
            }
            
        }
        .redefine .redefineContent {
            top: 229px !important;
            right: 435px !important;
          }
    }
}

@media (max-width: 991px){
    body.rtl .main-heading::after {
        right: 0 !important;
        left: 0 !important;
    }
    body.rtl .contactRowWrapper {
        padding-left: calc(var(--bs-gutter-x) * .5) !important;
        padding-right: calc(var(--bs-gutter-x) * .5) !important;
    }
    body.rtl .redefine .redefineContent {
        top: 169px !important;
        right: 335px !important;
      }
}

@media (max-width: 767px){
    body.rtl {
        .redefine .redefineContent {
            top: unset !important;
            right: unset !important;
          }
        
    }
}
@media (max-width: 640px){
    body.rtl {
        .sliderHeading {
            text-align: center !important;
            font-size: 35px !important;
            line-height: 65px !important;
        }
        
    }
}


@media (max-width: 480px){
    body.rtl {
        .sliderHeading {
            text-align: center !important;
            font-size: 30px !important;
            line-height: 50px !important;
        }
        
    }
}