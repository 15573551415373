@font-face {
    font-family: 'Abril Fatface';
    src: url('../fonts/AbrilFatface-Regular.woff2') format('woff2'),
        url('../fonts/AbrilFatface-Regular.woff') format('woff'),
        url('../fonts/AbrilFatface-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Flex';
    src: url('../fonts/RobotoFlex-Regular.woff2') format('woff2'),
        url('../fonts/RobotoFlex-Regular.woff') format('woff'),
        url('../fonts/RobotoFlex-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Roboto Lt';
    src: url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Rg';
    src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Md';
    src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Bd';
    src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Bk';
    src: url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}






/****************Arabic Fonts***************/

@font-face {
	font-family: "Noto Kufi Arabic Thin";
	src: url("../fonts/arabic/NotoKufiArabic-Thin.woff2") format("woff2"),
	  url("../fonts/arabic/NotoKufiArabic-Thin.woff") format("woff");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: "Noto Kufi Arabic ExtraLight";
	src: url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff2") format("woff2"),
	  url("../fonts/arabic/NotoKufiArabic-ExtraLight.woff") format("woff");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: "Noto Kufi Arabic Light";
	src: url("../fonts/arabic/NotoKufiArabic-Light.woff2") format("woff2"),
	  url("../fonts/arabic/NotoKufiArabic-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: "Noto Kufi Arabic Regular";
	src: url("../fonts/arabic/NotoKufiArabic-Regular.woff2") format("woff2"),
	  url("../fonts/arabic/NotoKufiArabic-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: "Noto Kufi Arabic Medium";
	src: url("../fonts/arabic/NotoKufiArabic-Medium.woff2") format("woff2"),
	  url("../fonts/arabic/NotoKufiArabic-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: "Noto Kufi Arabic SemiBold";
	src: url("../fonts/arabic/NotoKufiArabic-SemiBold.woff2") format("woff2"),
	  url("../fonts/arabic/NotoKufiArabic-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: "Noto Kufi Arabic Bold";
	src: url("../fonts/arabic/NotoKufiArabic-Bold.woff2") format("woff2"),
	  url("../fonts/arabic/NotoKufiArabic-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: "Noto Kufi Arabic ExtraBold";
	src: url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff2") format("woff2"),
	  url("../fonts/arabic/NotoKufiArabic-ExtraBold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
  }
  
  @font-face {
	font-family: "Noto Kufi Arabic Black";
	src: url("../fonts/arabic/NotoKufiArabic-Black.woff2") format("woff2"),
	  url("../fonts/arabic/NotoKufiArabic-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
  }

:root {
	--fHeading: "Abril Fatface";
	--fText: "Roboto Flex";
	--light: "Roboto Lt";
	--regular: "Roboto Rg";
	--medium: "Roboto Md";
	--bold: "Roboto Bd";
	--fBlack: "Roboto Bk";

	--arThin: "Noto Kufi Arabic Thin";
	--arExtraLight: "Noto Kufi Arabic ExtraLight";
	--arLight: "Noto Kufi Arabic Light";
	--arRegular: "Noto Kufi Arabic Regular";
	--arMedium: "Noto Kufi Arabic Medium";
	--arSemiBold: "Noto Kufi Arabic SemiBold";
	--arBold: "Noto Kufi Arabic Bold";
	--arExtraBold: "Noto Kufi Arabic ExtraBold";
	--arBlack: "Noto Kufi Arabic Black";
}